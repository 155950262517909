import autoBind from 'react-autobind';
import React from 'react';
import { Tooltip, Icon, Form, Drawer, Row, Col, Button, Radio, Skeleton, message } from 'antd';
import moment from 'moment';
//
import Utils from '../../../components/helpers/Utils';
import Globals from '../../../config/Globals';
import AccountUtils from '../../../components/helpers/AccountUtils';
//
import '../../../assets/stylesheets/CommonDocketDrawer.scss';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import EditableTitle from '../../../ui-components/EditableTitle';
import CommonConfirmationModal from '../Modals/CommonConfirmationModal';
import CommonDocketDrawerInformationTab from './CommonDocketDrawerInformationTab';
import CommonDocketDrawerFilesTab from './CommonDocketDrawerFilesTab';
import CommonHardDeleteModal from '../Modals/CommonHardDeleteModal';
import CommonRecoverModal from '../Modals/CommonRecoverModal';
import CommonSoftDeleteModal from '../Modals/CommonSoftDeleteModal';

const TabTypes = {
  INFO: 'information',
  FILES: 'files',
};
//props are: app, onReload
class _CommonDocketDrawer extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isVisible: false, isEditing: false, isDownloading: false,
                   isSaving: false, isDeleting: false, selectedTab: TabTypes.INFO,
                   additionalIDs: [], selectedDocket: {}
    };
  }

  // Public
  async show(docketID, additionalIDs, focusedDocumentID) {
    this.startLoading();
    this.setState({ isVisible: true, selectedTab: focusedDocumentID ? TabTypes.FILES : TabTypes.INFO, additionalIDs });
    await this.loadDocketInfo(docketID, additionalIDs, null, null, null, focusedDocumentID);
    if (focusedDocumentID && this.filesTab) this.filesTab.setSelectedFile(focusedDocumentID);
  }
  //Getters
  getCurrentDocket() { return this.state.isVisible ? this.state.selectedDocket : null; }
  inOperation() {
    return (
      this.state.isSaving ||
      this.state.isDeleting ||
      this.state.isEditing ||
      this.state.isDownloading ||
      this.state.isLoading ||
      (this.filesTab && this.filesTab.inOperation())
    );
  }
  forceClose() { this._resetState(); }

  // Action handlers
  async handleDrawerReload(forceClose = false) {
    if (forceClose) {
      this.forceClose();
      await this.props.onReload();
      return;
    }
    if (this.filesTab) this.filesTab.forceClose();
    const { selectedDocket, additionalIDs } = this.state;
    await this.loadDocketInfo(selectedDocket.id, additionalIDs);
    await this.props.onReload(); //ask to search reload after
  }
  handleCloseDrawer() {
    const { isSaving, isDeleting } = this.state;
    if (isSaving || isDeleting) return;
    this._resetState();
  }
  handleTabChange(e) {
    const { selectedTab, isEditing, isSaving, isDeleting } = this.state;
    if (isSaving || isDeleting) return;
    if (selectedTab === TabTypes.INFO && isEditing) this.handleCancelEdit();
    this.setState({ selectedTab: e.target.value });
  }
    //Multi-actions (batch, document or docket)
  handleRecover(record, isBatch) { this.modalRecover.show(record, isBatch); }
  handlePermanentlyDelete(record, isBatch) { this.modalHardDelete.show(record, isBatch); }
  handleSoftDelete(record, isBatch) {
    if (record && isBatch) {
      this.modalSoftDelete.show(record, isBatch);
    } else {
      const { selectedDocket } = this.state;
      if (this.confirmationModal) {
        this.confirmationModal.show('Remove Docket',
          `Attention: This docket contains ${selectedDocket.documents.length} documents. All documents will also be removed along with the docket.`
        );
      }
    }
  }
  async handleDownload(record, isBatch) {
    if (isBatch && !isBatch.target) {
      const { selectedDocket } = this.state;
      this.setState({ isDownloading: true });
      this.props.app.operationsController.newBatchDownloadOperationView([], record.map(f => ({ id: f, docketID: selectedDocket.id })), () => {
        this.setState({ isDownloading: false });
      })
    } else if (!record.docketID) { //Docket
      const { selectedDocket } = this.state;
      this.setState({ isDownloading: true });
      this.props.app.operationsController.newDocketDownloadOperationView(selectedDocket, () => {
        this.setState({ isDownloading: false });
      })
    } else { //DOCUMENT
      this.setState({ isDownloading: true });
      this.props.app.operationsController.newDocumentDownloadOperationView(record, () => {
        this.setState({ isDownloading: false });
      });
    }
  }
  handleTransfer(multiselectionIDs, isMove) {
    this.props.app.operationsController.newTransferOperationView(
      [],
      multiselectionIDs.map(id => ({id, docketID: this.state.selectedDocket.id})),
      isMove
    );
  }
    //Docket actions
  handleEditDocket() {
    this.setState({ isEditing: true, selectedTab: TabTypes.INFO });
    if (this.docketTitle) this.docketTitle.setIsEditing(true);
  }
  handleUpload() { this.props.app.dropController.openDialog(); }
  async handleConfirmDeleteDocket() {
    this.setState({ isDeleting: true });
    const { selectedDocket, additionalIDs } = this.state;
    const resp = await this.props.app.api.v2.vaultDocket.deleteDocket(additionalIDs.vaultID, selectedDocket.id);
    if (resp.statusCode === 200) {
      message.success('Docket successfully removed');
      this.forceClose();
    } else {
      this.setState({ isDeleting: false });
      this.props.app.alertController.showAPIErrorAlert(null, resp);
    }
  }
    //Edit support
  handleCancelEdit() {
    this.setState({ isEditing: false });
    if (this.informationTab) this.informationTab.setAttributes(this.state.selectedDocket);
    if (this.docketTitle) this.docketTitle.setIsEditing(false);
  }
  async handleSaveChanges() {
    //Get info
    let attributes = await this.informationTab.validateAttributes();
    if (!attributes) return;
    attributes = this._treatAttributes(
      attributes.form && attributes.form.attributes ? attributes.form.attributes : null
    );
    //
    const tags = await this.informationTab.getTags();
    if (!tags) return;
    //
    await this.docketTitle.validate();
    const docketTitle = await this.docketTitle.getValue();
    if (!docketTitle) return;
    //
    let notes = await this.informationTab.getNotes();
    if (!notes) notes = '';
    //
    const { selectedDocket, additionalIDs } = this.state;
    this.setState({ isSaving: true });
    const saved = await this._updateDocket(selectedDocket, attributes, tags, docketTitle, notes);
    if (saved) {
      this.setState({ isEditing: false, isSaving: false });
      this.loadDocketInfo(selectedDocket.id, additionalIDs);
    } else {
      this.setState({ isSaving: false });
    }
  }

  /* UI */
  render() {
    return (
      <>
        <Drawer className="docketDetails" title="Docket Details" placement="right" onClose={this.handleCloseDrawer} visible={this.state.isVisible}>
          <Row>
            <Row type="flex" justify="space-between" align="middle" gutter={[16, 16]}>
              <Col xs={12}>
                <Skeleton title={false} paragraph={{ rows: 1 }} loading={this.state.isLoading} active>
                  <EditableTitle
                    value={this.state.selectedDocket.name}
                    level={4}
                    inputName="docket_title"
                    inputOptions={{ rules: [{ required: true, message: 'Please, insert a name for the docket!' }] }}
                    form={this.props.form}
                    inputProps={{ placeholder: '* Docket Title' }}
                    {...Utils.propagateRef(this, 'docketTitle')}
                  />
                  {this.state.selectedDocket?.deleted && <Tooltip placement="top" title={`This docket is marked for deletion and will be permanently removed after ${Utils.getDateOnUIFormatByTimestamp(this.state.selectedDocket?.ttl * 1000)}.`}>
                    <Icon type="warning" theme='filled' style={{ marginLeft: '8px', color: 'orange', fontSize: 18 }} />
                  </Tooltip>}
                </Skeleton>

              </Col>
              <Col>{this._renderButtons()}</Col>
            </Row>
            {this._renderTabs()}
          </Row>
          <CommonHardDeleteModal app={this.props.app} onReload={this.handleDrawerReload} {...Utils.propagateRef(this, 'modalHardDelete')} />
          <CommonRecoverModal app={this.props.app} onReload={this.handleDrawerReload} {...Utils.propagateRef(this, 'modalRecover')} />
          <CommonSoftDeleteModal app={this.props.app} onReload={this.handleDrawerReload} {...Utils.propagateRef(this, 'modalSoftDelete')} />
          <CommonConfirmationModal onConfirmation={this.handleConfirmDeleteDocket} confirmationText="Remove Docket"
                                   cancellationText="Cancel" onCancel={() => { }} {...Utils.propagateRef(this, 'confirmationModal')} />
        </Drawer>

      </>
    );
  }

  /* Private Utils */
  _resetState() {
    this.stopLoading(false);
    this.setState({ isVisible: false, isEditing: false, isDownloading: false, isSaving: false,
                    isDeleting: false, selectedTab: TabTypes.INFO, additionalIDs: [], selectedDocket: {} });
    if (this.informationTab) this.informationTab.resetAttributes();
    if (this.filesTab) this.filesTab.forceClose();
  }
  _treatAttributes(attributes) {
    if (attributes && Object.keys(attributes).length) {
      return Object.keys(attributes).map((key) => {
        const attribute = this.props.app.sharedCache().getCurrentVaultAttributeByID(key);
        return { id: key, value: attribute.type.toLowerCase() === 'date' ?
                 new Date(moment(attributes[key]).format(Globals.DefaultUIDateTimeFormat)).getTime() :
                 attributes[key],
        };
      });
    }
    return [];
  }

  // UI
  _renderTabs() {
    const { selectedTab } = this.state;
    return (
      <Row>
        <Radio.Group value={selectedTab} onChange={this.handleTabChange} buttonStyle="solid"
          className="docketDetails-toggleRadioButton" disabled={this.state.isLoading}>
          <Radio.Button value={TabTypes.INFO}>Information</Radio.Button>
          <Radio.Button value={TabTypes.FILES}>
            Documents ({this.state.selectedDocket && this.state.selectedDocket.docketNumDocs > 0 ? this.state.selectedDocket.docketNumDocs : '0'})
          </Radio.Button>
        </Radio.Group>

        {this._renderDocketInformationTabView()}
        {this._renderDocketFilesTabView()}
      </Row>
    );
  }
  _renderDocketInformationTabView() {
    const isVisible = this.state.selectedTab === TabTypes.INFO;
    return (
      <Row style={{ display: isVisible ? 'block' : 'none' }}>
        <CommonDocketDrawerInformationTab app={this.props.app} form={this.props.form}
          isLoading={this.state.isLoading} isEditing={this.state.isEditing}
          selectedDocket={this.state.selectedDocket} {...Utils.propagateRef(this, 'informationTab')}
        />
      </Row>
    );
  }
  _renderDocketFilesTabView() {
    const isVisible = this.state.selectedTab === TabTypes.FILES;
    return (
      <Row style={{ display: isVisible ? 'block' : 'none' }}>
        <CommonDocketDrawerFilesTab app={this.props.app} isLoading={this.state.isLoading} selectedDocket={this.state.selectedDocket}
          focusedDocumentID={this.state.focusedDocumentID} additionalIDs={this.state.additionalIDs} onPageChanged={this.loadDocketInfo}
          {...Utils.propagateRef(this, 'filesTab')} onRecover={this.handleRecover} onHardDelete={this.handlePermanentlyDelete}
          onDelete={this.handleSoftDelete} onDownload={this.handleDownload} onTransfer={this.handleTransfer}/>
      </Row>
    );
  }
  _renderButtons() {
    const { isEditing, isSaving, isDeleting, isLoading, selectedDocket } = this.state;
    const account = this.props.app.sharedCache().getCurrentVaultUser();
    const allowDeletion = AccountUtils.hasDeletePermission(account?.role);
    if (isEditing) {
      return (
        <>
          <Button disabled={isSaving} onClick={this.handleCancelEdit}> Cancel </Button>{' '}
          <Button type="primary" onClick={this.handleSaveChanges} loading={isSaving}> Save Changes </Button>
        </>
      );
    }
    return (
      <>
        {!selectedDocket.deleted && <Button disabled={isLoading || isDeleting || selectedDocket.deleted} onClick={this.handleUpload}> Add Document </Button>}
        {selectedDocket.deleted && <Button disabled={isLoading || isDeleting || !selectedDocket.deleted} onClick={this.handleRecover.bind(this, this.state.selectedDocket, false)}> Recover </Button>}{' '}
        <Button disabled={isLoading || isDeleting} loading={this.state.isDownloading} icon="arrow-down" onClick={this.handleDownload.bind(this, this.state.selectedDocket)}/>
        {!selectedDocket.deleted && <Button disabled={isLoading || isDeleting || selectedDocket.deleted} onClick={this.handleEditDocket}> Edit </Button>}
        {selectedDocket.deleted && <Button disabled={isLoading || isDeleting || !selectedDocket.deleted || !allowDeletion} onClick={this.handlePermanentlyDelete.bind(this, this.state.selectedDocket, false)}> Permanently Delete </Button>}{' '}
        {!selectedDocket.deleted && <Button disabled={isLoading || isDeleting || selectedDocket.deleted || !allowDeletion} onClick={this.handleSoftDelete} loading={isDeleting}> Delete </Button>}
      </>
    );
  }

  /* Private API calls */
  async _updateDocket(selectedDocket, attributes, tags, docketTitle, notes) {
    const resp = await this.props.app.api.v2.vaultDocket.updateDocket(selectedDocket.vaultID, selectedDocket.id, {
      name: docketTitle, tags: tags || [], attributes, notes,
    });
    if (resp.statusCode != 200) {
      this.props.app.alertController.showAPIErrorAlert('Error!', resp);
      return false;
    } return true;
  }

  async loadDocketInfo(docketID, { vaultID }, from, sortOrder, sortField, documentID, filterValue) {
    this.startLoading();
    let docketInfo = {}; let docketNumDocs = 0; let documents = []; const reqs = [];
    if (this.state.selectedDocket.id) docketInfo = {...this.state.selectedDocket, documents };
    if (!this.state.selectedDocket.id) reqs.push(this.props.app.api.v2.vaultDocket.getDocketByID(vaultID, docketID));
    if (documentID) reqs.push(this.searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField, documentID, null));
    if (filterValue) reqs.push(this.searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField, null, filterValue));
    if (!filterValue) reqs.push(this.searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField, null, null));
    const fails = (resp) => this.props.app.alertController.showAPIErrorAlert(null, resp);
    const success = ({body}) => {
      if (body.id) docketInfo = body;
      if (body.results) {
        body.results.forEach(d => documents.push(d));
        docketNumDocs = body.total;
      }
    }
    await Utils.execRequests(reqs, fails, success);
    documents = Object.values(documents.reduce((acc, cur) => Object.assign(acc, {[cur.id]:cur}), {}));
    docketInfo = { ...docketInfo, documents, docketNumDocs };
    if (this.informationTab) this.informationTab.setAttributes(docketInfo);
    this.setState({ selectedDocket: docketInfo });
    this.stopLoading(false);
  }
  async searchDocketDocuments(vaultID, docketID, from, sortOrder, sortField, documentID, filterValue) {
    const searchTerm = {
      type: Globals.SearchTypes.DOCUMENT, quantifier: Globals.SearchQuantifier.ALL, from: from || 0,
      sortOrder: sortOrder || 'desc', sortField: sortField || 'createdOn',
      filters: [{field: "docketID", qualifier: Globals.SearchQualifiers.EQUAL, value: [docketID]}]
    };
    if (documentID) searchTerm.filters.push({field: "id", qualifier: Globals.SearchQualifiers.EQUAL, value: [documentID]});
    if (filterValue) searchTerm.filters.push({field: "name", qualifier: Globals.SearchQualifiers.CONTAINS, value: [filterValue]});
    return await this.props.app.api.v2.docketSearch.docketDocumentSearch(searchTerm, vaultID);
  }
}

const CommonDocketDrawer = Form.create()(_CommonDocketDrawer);
export default CommonDocketDrawer;
