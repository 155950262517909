export default class Globals {}

Globals.URL_Path_ID_Placeholder = 'id';
Globals.URL_Path_ID2_Placeholder = 'id2';
Globals.URL_NewIndentifier = 'new';
Globals.URLQueryParam_VaultAlias = 'vaultAlias';
Globals.URLQueryParam_Subscribe = 'subscribe';
Globals.WEBSITE_LINK = 'https://www.ikonintegration.com/';
Globals.WEBSITE_LABEL = 'ikonintegration.com';

Globals.URLQueryParam_SearchTerm = 'searchTerm';
Globals.URLQueryParam_SearchFiltersQuantifier = 'quantifier';
Globals.URLQueryParam_SearchFilters = 'filters';
Globals.URLQueryParam_SearchQuantifier = 'searchQuantifier';
Globals.URLQueryParam_SearchType = 'searchType';

Globals.Offline_VaultAlias = '2cubuhqvx0c27obrtqglh5udbkw';

// Breakpoint in px to conver layout to mobile version
Globals.MobileBreakpoint = 600;

Globals.SubscriptionPackagesIDs = {
  FREE: 'FREE',
  LITTLEPIGGY: 'LITTLEPIGGY',
  BIGPIGGY: 'BIGPIGGY',
};
Globals.Limits_SearchTypes = {
  SIMPLE: 'SIMPLE',
  ADVANCED: 'ADVANCED',
};
Globals.VaultSettings_AvailableColorsSchema = {
  //Allowed themes
  // DARK: 'Dark Theme',
  LIGHT: 'Light Theme',
}; //keys are API defined, values are labels
Globals.VaultSettings_CustomAttributeTypes = {
  FLOAT: 'FLOAT',
  INTEGER: 'INTEGER',
  NUMERIC: 'NUMERIC', //compatibility
  STRING: 'STRING',
  DATE: 'DATE',
};
Globals.VaultSettings_CustomAttributeTypes_Labels= {
  // FLOAT: 'Floating number',
  STRING: 'Text',
  DATE: 'Date',
  INTEGER: 'Number',
};
Globals.VaultSettings_AvailableRegions = {
  //Allowed vault/bucket regions
  'ca-central-1': 'Canada (ca-central-1)',
  'us-west-2': 'US West (us-west-2)',
}; //keys are API defined, values are labels
Globals.AvailableRegistrationRoles = {
  MANAGER: 'MANAGER',
  EDITOR: 'EDITOR',
  CONTRIBUTOR: 'CONTRIBUTOR',
  MEMBER: 'MEMBER',
};
Globals.AvailableRegistrationRolesDescriptions = {
  MANAGER: 'Editor’s permissions plus the ability to manage users',
  EDITOR: 'Search, download, upload and remove documents',
  CONTRIBUTOR: 'Search, download and upload documents',
  MEMBER: 'Search and download documents',
};
Globals.Vault_Roles = {
  OWNER: 'OWNER',
  MANAGER: 'MANAGER',
  EDITOR: 'EDITOR',
  CONTRIBUTOR: 'CONTRIBUTOR',
  MEMBER: 'MEMBER',
};
Globals.Vault_RolesDescriptions = {
  // OWNER: 'OWNER',
  MANAGER: 'Full permission to a vault, including the ability to invite and remove users.',
  EDITOR: 'Has the ability to upload and remove documents to/from the vault.',
  CONTRIBUTOR: 'Has the ability to upload documents but cannot remove them.',
  MEMBER: 'Can search and download documents but cannot upload or delete them.',
};
Globals.Vault_RolesDisplayNames = {
  OWNER: 'Owner',
  MANAGER: 'Manager',
  EDITOR: 'Editor',
  CONTRIBUTOR: 'Contributor',
  MEMBER: 'Member',
};
Globals.DocumentExtractorStatuses = {
  UNPROCESSED: 'UNPROCESSED',
  PROCESSING: 'PROCESSING',
  PAUSED: 'PAUSED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED'
};
Globals.DocumentPreviewStatuses = {
  UNPROCESSED: 'UNPROCESSED',
  PROCESSING: 'PROCESSING',
  PAUSED: 'PAUSED',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  UNAVAILABLE: 'UNAVAILABLE'
};
Globals.Document_State = {
  UNPROCESSED: 'UNPROCESSED',
  PROCESSING: 'PROCESSING',
  EXTRACTING: 'EXTRACTING',
  WRITING: 'WRITING',
  THUMBNAIL: 'THUMBNAIL',
  INDEXING: 'INDEXING',
  RETRYING: 'RETRYING',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
};
Globals.SearchTypes = {
  ALL: 'ALL',
  DOCKET: 'DOCKET',
  DOCUMENT: 'DOCUMENT',
};
// DO NOT ADD DOCKETID OR ID
Globals.SearchFields = {
  NAME: 'name',
  TAGS: 'tags',
  CONTENT: 'content',
  ATTRIBUTES_VAL: 'attributes.value',
  FILENAME: 'fileName',
  FILETYPE: 'fileType',
  FILESIZE: 'fileSize',
  DESCRIPTION: 'description',
  CREATEDON: 'createdOn',
  UPDATEDON: 'updatedOn',
  NUMBEROFFILES: 'docketNumDocs',
  DOCKETSIZE: 'docketSize',
  DOCKETNAME: 'docketName',
  ALL: 'ALL',
};
Globals.SearchQualifiers = {
  CONTAINS: 'CO',
  LESSEQUAL: 'LE',
  LESSTHAN: 'LT',
  GREATEREQUAL: 'GE',
  GREATERTHAN: 'GT',
  BETWEEN: 'BE',
  EQUAL: 'EQ',
  NOTEQUAL: 'NEQ',
};
Globals.SearchQuantifier = {
  ANY: 'ANY', //OR
  ALL: 'ALL', //AND
};
Globals.AccountManualLockTypes = {
  UNLOCKED: 0,
  DATAWRITELOCK: 1,
  DATAWRITESEARCHLOCK: 2,
  ALLWRITESEARCHLOCK: 3,
  READWRITELOCK: 4,
  FULLLOCK: 5,
};
Globals.Vault_PurgeStatuses = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  FINALIZING: 'FINALIZING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};
//Defaults
Globals.Documents_MAX_Size = 209715200; //200mb
Globals.SearchDateFormat = 'YYYY-MM-DD';
Globals.Search_MinimumCharacters = 3;
Globals.Search_PagingItemsPerPage = 25;
Globals.DocketDrawer_MaxDocuments = 500;
Globals.VaultPurge_AutoRefreshDelay = 5000;

//Document UI
Globals.GetBadgeStatusByDocumentState = (extractionStatus, previewStatus) => {
  if (previewStatus === Globals.DocumentPreviewStatuses.UNAVAILABLE) return 'default';
  if (extractionStatus === Globals.DocumentExtractorStatuses.UNPROCESSED && previewStatus === Globals.DocumentPreviewStatuses.PROCESSING) return 'processing';
  if (extractionStatus === Globals.DocumentExtractorStatuses.PROCESSING || previewStatus === Globals.DocumentPreviewStatuses.PROCESSING) return 'processing';
  if (extractionStatus === Globals.DocumentExtractorStatuses.PAUSED && previewStatus === Globals.DocumentPreviewStatuses.PAUSED) return 'warning';
  if (extractionStatus === Globals.DocumentExtractorStatuses.FAILED || previewStatus === Globals.DocumentPreviewStatuses.FAILED) return 'error';
  if (extractionStatus === Globals.DocumentExtractorStatuses.COMPLETED && previewStatus === Globals.DocumentPreviewStatuses.COMPLETED) return 'success';
  if (previewStatus === Globals.DocumentPreviewStatuses.UNAVAILABLE) return 'default';
  return 'default';
};
Globals.GetTextSearchByType = (type) => {
  if (type == Globals.SearchTypes.ALL) return 'Everything';
  else if (type == Globals.SearchTypes.DOCUMENT) return 'Documents';
  else return 'Dockets';
};

//Drag and Drop
Globals.DnDRejectionReasons = {
  MAX_TOTAL_SIZE: 0,
  MIN_SIZE: 1,
};
Globals.DnDRejectionReasonsString = (r) => {
  if (r == Globals.DnDRejectionReasons.MAX_TOTAL_SIZE) return `File over 200MB`;
  if (r == Globals.DnDRejectionReasons.MIN_SIZE) return 'File empty';
  return 'Invalid file';
};

//Errors messages
Globals.DockerDrawer_MaxDocuments_Alert = 'The current Docket exceeds the maximum number of documents that can be displayed on this list. If you want to find a specific document, please use the search function/search feature.';
Globals.AccountLockActionErrorMessage = "This action is currently locked for all vaults from this vault's owner. For more information, please contact the vault owner or our support..";
Globals.AccountLock_UploadDisabledMessage = 'UPLOADS ARE DISABLED FOR THIS VAULT'; //homepage
Globals.AccountLock_SearchDisabledMessage = "Search is currently locked for all vaults from this vault's owner. For more information, please contact the vault owner or our support..";
Globals.AccountLock_VaultLockedMessage = "This vault is currently locked by the administrator and no access is allowed. For more information, please contact the vault owner or our support..";

//Launch Config
Globals.LaunchConfig_AppType = {
  VAULT: 'vault', DOCKET: 'docket'
};
Globals.LaunchConfig_DocketViewTypes = {
   THUMBNAIL: 'thumbnail', LIST: 'list',
   PREVIEW: 'preview',
}

//Transfer
Globals.Transfer_Operation = {
  MOVE: 'move', COPY: 'copy'
};
Globals.Transfer_Type = {
  DOCUMENT: 'document', DOCKET: 'docket'
};
Globals.Transfer_Phase = {
  DOCKETSCREATION: 'docketsCreation', DOCUMENTSTRANSFER: 'documentsTransfer', DOCKETSDELETION: 'docketsDeletion'
}
Globals.Transfer_MaxList = 200;
Globals.FileTypes = {
  DOCKET: 'DOCKET',
  DOCUMENT: 'DOCUMENT',
};
