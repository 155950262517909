module.exports = {
  IDMClientTokenKey: "af4bbc7e-286d-5def-bfda-7b2b67604db8",
  IDMClientTokenSecret: "f8a2633f-0c2a-592b-a7e4-97dee9dbf1c4",
  IDMClientOptions: {
    externalAuth: false,
    externalAuthDomain: "dakeryn-apps.com",
    externalAuthPath: "/login",
    externalValidatePath: "/validate",
    externalRegistrationPath: "/register",
    cookiesEndpoint: "https://dakeryn-apps.com/a",
    apiEndpoint: "https://api-auth-dev.dakeryn-apps.com",
    roles: {
      USER: "85436518-6c4_Docket",
      SYSADMIN: "762aeb14-393_Docket",
      BILLINGADMIN: "29ba2f16-a8e_Docket",
      HELPDESK: "16e2fea8-da1_Docket"
    },
    partitions: {}
  },
  ApplicationName: "Files 4 Ever",
  ApplicationDomain: "dakeryn-apps.com",
  ApplicationAuthDomain: "dakeryn-apps.com",
  AccountAppSubdomain: "myaccount-dev",
  IntegrationsDesktopSubdomain: "desktop",
  IntegrationsMobileSubdomain: "mobile",
  ApplicationAPIEndpoint: "https://api-v2-dev.dakeryn-apps.com",
  ApplicationThemeEndpoint: "dxp2g1gvohzrk.cloudfront.net",
  ApplicationGoogleAnalyticsKey: null,
  ApplicationRoutes: {
    login: "/",
    homepage: "/",
    settings: "/settings",
    profile: "/profile",
    vaults: "/vaults",
    dockets: "/dockets",
    adminUserInvitation: "/user-invitation"
  },
  DatadogOptions: {
    applicationId: "31fa36fd-7a17-48b5-9509-a99d0f12df3a",
    clientToken: "pub2f9e05cf1d0f9f127c03c968b2d3d20f",
    site: "datadoghq.com",
    service: "orangepiggy-fe",
    env: "development",
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "allow"
  },
  Slaask: {
    enabled: false,
    widgetKey: "spk-46e4fb9a-d23b-4ea2-a388-238c6cdb1f79",
    secretKey: "ssk-secret-86dd8afb-3143-4ebf-a507-81631fb462be"
  }
}