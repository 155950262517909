import React from 'react';
import autoBind from 'react-autobind';
import { Row, Tag } from 'antd';
import VirtualizedSelect from 'react-virtualized-select';
import Select from 'react-select';
//
import 'react-virtualized-select/styles.css';
import '../../../assets/stylesheets/CommonTagSelect.scss';
//docs:
// - https://github.com/bvaughn/react-virtualized-select
// - https://github.com/JedWatson/react-select/tree/v1.3.0
// - http://bvaughn.github.io/react-virtualized-select/
//
export default class CommonTagSelect extends React.Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { selectedValues: [] };
  }

  componentDidMount() {
    if (this.props.values) {
      this.setState({ selectedValues: this.props.values });
    }
  }

  setValues(values) {
    this.setState({ selectedValues: values });
  }

  render() {
    return (
      <VirtualizedSelect
        multi
        labelKey="label"
        valueKey="value"
        isSearchable
        clearable
        optionHeight={30}
        loadOptions={this._loadTags}
        className="tagsSelect"
        selectComponent={Select.AsyncCreatable}
        onChange={this._updateValue}
        value={this.state.selectedValues}
        optionRenderer={this._renderItem}
        clearValueText="Clear tag"
        noResultsText="No tags found!"
        searchPromptText="Type to search"
        placeholder="Select a tag..."
      />
    );
  }
  getSelectedTags() {
    return this.state.selectedValues.map((tag) => tag.value);
  }
  /* privates */
  //Actions
  _updateValue(newValue) {
    this.setState({ selectedValues: newValue });
    if (this.props.onChange) this.props.onChange(newValue);
  }
  // UI
  _renderItem(val) {
    const isExistingItem = val.option.count != undefined;
    return (
      <Row
        key={val.key}
        type="flex"
        align="middle"
        className={isExistingItem ? 'tagSelectExistingItem' : 'tagSelectItem'}
        onClick={() => val.selectValue(val.option)}
        style={val.style}
      >
        {val.option.value}
        {val.option.count && (
          <Tag color={this.getBadgeColorByCount(val.option.count)} className="tagSelectCountBadge">
            {val.option.count}
          </Tag>
        )}
      </Row>
    );
  }
  // API
  async _loadTags() {
    /* eslint-disable no-async-promise-executor */
    /* eslint-disable prefer-promise-reject-errors */
    const vaultID = await this.props.app.sharedCache().getCurrentVaultID();
    const resp = await this.props.app.api.v2.vaultTag.getVaultTags(vaultID);
    if (resp.statusCode == 200 && resp.body && resp.body.tags) {
      return {
        options: resp.body.tags
          .map((tag) => {
            return { value: tag.value, label: tag.value, count: tag.count };
          })
          .sort((a, b) => b.count - a.count),
      };
    } else return [];
  }
  //Color helpers
  getBadgeColorByCount(count) {
    const colours = [
      { count: 0.0, color: { r: 0x9d, g: 0xca, b: 0xfe } },
      { count: 100.0, color: { r: 0x17, g: 0x83, b: 0xfc } },
    ];
    let selectedColour = 1;
    for (let i = 1; i < colours.length - 1; i++) {
      if (count < colours[i].count) {
        selectedColour = i;
        break;
      }
    }
    let lower = colours[selectedColour - 1];
    let upper = colours[selectedColour];
    let rangeCount = (count - lower.count) / (upper.count - lower.count);
    let countLower = 1 - rangeCount;
    let color = {
      r: Math.floor(lower.color.r * countLower + upper.color.r * rangeCount),
      g: Math.floor(lower.color.g * countLower + upper.color.g * rangeCount),
      b: Math.floor(lower.color.b * countLower + upper.color.b * rangeCount),
    };
    return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
  }
}
