import React from 'react';
import autoBind from 'react-autobind';
//
import { Layout, Button, Icon } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
//
import uploadDragnDrop from '../../../assets/images/gifs/upload-dragndrop.gif';
import uploadSelect from '../../../assets/images/gifs/upload-select.gif';
import createDocket from '../../../assets/images/gifs/create-docket.gif';
//Style
import '../../../assets/stylesheets/WizardView.scss';
//
/* eslint max-classes-per-file: ["error", 2] */
export default class WizardView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      isLoading: false,
      activeIndex: 0,
    };

    this.slides = [
      <>
        <h1>How to upload your documents to Files 4 Ever</h1>
        <h2>Drag and Drop folders and files directly to Files 4 Ever</h2>
        <div className="image-container">
          <img src={uploadDragnDrop} alt="How to upload files" width="500" />
        </div>

        <Button type="primary" size="large" style={{ marginTop: 24 }} onClick={this.handleNextStep}>
          Next <Icon type="arrow-right" />
        </Button>
      </>,
      <>
        <h1>How to upload your documents to Files 4 Ever</h1>
        <h2>Select folders and files from your computer</h2>
        <div className="image-container">
          <img src={uploadSelect} alt="How to upload files" width="500" />
        </div>

        <Button type="primary" size="large" style={{ marginTop: 24 }} onClick={this.handleNextStep}>
          Next <Icon type="arrow-right" />
        </Button>
      </>,
      <>
        <h1>Upload files to a Docket</h1>
        <h2>A Docket is like a folder where you can organize your files in Files 4 Ever.</h2>
        <div className="image-container">
          <img src={createDocket} alt="How to upload files" width="460" />
        </div>

        <Button type="primary" size="large" style={{ marginTop: 24 }} onClick={this.handleGoToMyVault}>
          Go to my Vault!
        </Button>
      </>,
    ];
  }

  handleNextStep() {
    this.setState((prevState) => ({
      ...prevState,
      activeIndex: prevState.activeIndex + 1,
    }));
  }

  handleChangeWizard(index) {
    this.setState({ activeIndex: index });
  }

  handleGoToMyVault() {
    this.props.app.completeVaultWizard();
  }

  //UI
  render() {
    // const isLoading = this.state.isLoading || this.props.app.isAuthenticating;

    return (
      <Layout.Content className="wizardContainer">
        <div className="carousel">
          {this.slides.map((slide, index) => (
            <div className={index === this.state.activeIndex ? 'visible' : ''} key={Math.random()}>
              {slide}
            </div>
          ))}
        </div>

        <div className="carousel-controllers">
          {this.slides.map((_, index) => (
            <button
              type="button"
              className={index === this.state.activeIndex ? 'active' : ''}
              key={Math.random()}
              onClick={this.handleChangeWizard.bind(this, index)}
            >
              <span />
            </button>
          ))}
        </div>
      </Layout.Content>
    );
  }
}
