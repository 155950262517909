import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Row, Col, Typography, Button, Spin } from 'antd';
import CommonUploadNavigationView from './CommonUploadNavigationView';
import CustomComponent from '../../../ui-components/CustomComponent';
//
import Utils from '../../../components/helpers/Utils';
//
import CommonEditAttributesNavigationView from './CommonEditAttributesNavigationView';
import CommonConfirmationModal from '../../commonComponents/Modals/CommonConfirmationModal';
import CommonDocketSearch from '../../commonComponents/DocketSearch/CommonDocketSearch';
import CommonCreateDocketNavigationView from './CommonCreateDocketNavigationView';
//
import '../../../assets/stylesheets/DocketController.scss';
//
export default class DocketNavigationView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = {
      files: this.props.files,
      isUnmounting: false,
      selectedFile: null,
      selectedFileIndex: null,
      showDocketAttributes: true,
      selectedDocket: this.props.docket || null,
      isLoading: !this.props.app.sharedCache().selectedVault.vaultNumDockets,
      vaultNumDockets: this.props.app.sharedCache().selectedVault.vaultNumDockets || undefined
    };
  }

  //Life cycle
  componentDidMount() {
    this._loadCurrentVaultNumDockets();
  }
  componentDidUpdate() {
    if (this.state.files.length === 0 && !this.state.isUnmounting) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isUnmounting: true }, () => {
        this.handleCancelView();
      });
    }
  }
  receiveProps(props) {
    console.log('DocketController - Received values', props);
    if (props.selectedDocket) {
      //Get current files and set it attrs based
      //on the selected docket
      const files = this._setFilesAttributes(props.files || this.state.files, props.selectedDocket);
      this.setState({ ...props, files });
    } else {
      this.setState({ ...props });
    }
  }
  //Actions
  handleUpload() {
    if (this.state.selectedDocket) {
      this.props.navigationController.pushView(
        <CommonUploadNavigationView app={this.props.app} files={this.state.files} docket={this.state.selectedDocket} controller={this.props.controller} />
      );
    }
  }
  handleEdit() {
    if (this.state.selectedDocket) {
      this.props.navigationController.pushView(
        <CommonEditAttributesNavigationView app={this.props.app} files={this.state.files} docket={this.state.selectedDocket} controller={this.props.controller} />
      );
    }
  }
  //Modal file removal
  handleFileDelete(file, index) {
    this.setState(
      {
        selectedFile: file,
        selectedFileIndex: index,
      },
      () => {
        this.confirmationModal.show(
          'Remove File',
          `Are you sure you want to remove “${file.document_title}” from your upload queue?`
        );
      }
    );
  }
  handleFileDeleteModalOk() {
    const index = this.state.selectedFileIndex;

    this.setState({
      selectedFile: null,
      selectedFileIndex: null,
    });

    this._removeFileByIndex(index);
  }
  handleFileDeleteModalCancel() {
    this.setState({
      selectedFile: null,
      selectedFileIndex: null,
    });
  }
  //
  handleCancelView() {
    if (this.state.selectedDocket) {
      this.setState({
        selectedDocket: null,
      });
    } else {
      this.props.navigationController.popView({
        popProps: { files: [] }, //reset
      });
    }
  }
  //Drag and drop
  handleDragFile(dndEvent) {
    if (dndEvent.approvals.length > 0) {
      const { files, selectedDocket } = this._appendFiles(dndEvent.approvals)
      this.setState({ files, selectedDocket });
    }
  }
  // Docket Select and Create
  handleSelectDocket(selectedDocket) {
    const files = this._setFilesAttributes(this.state.files, selectedDocket);
    this.setState({ selectedDocket, files });
  }
  handleDocketCreate() {
    this.props.navigationController.pushView(
      <CommonCreateDocketNavigationView
        app={this.props.app}
        appendFiles={this._appendFiles}
        callbackContext={{ files: this.state.files }}
      />
    );
  }

  // UI
  render() {
    return (
      <Layout.Content className="DocketController-content commonTabContent">
        {this._renderHeader()}
        {this._renderDocketSearch()}
        {this._renderFiles()}
        <Row type="flex" justify="end" gutter={16} className="DocketController-fixed">
          <Col>
            <Button type="ghost" onClick={this.handleCancelView}>Cancel</Button>
          </Col>
          {!!this.state.selectedDocket && (
            <Col>
              <Button onClick={this.handleEdit}>Edit Files</Button>
            </Col>
          )}
          <Col>
            <Button type="primary" disabled={!this.state.selectedDocket} onClick={this.handleUpload}>
              Upload
            </Button>
          </Col>
        </Row>
      </Layout.Content>
    );
  }
  _renderSearchDocketText() {
    return this.state.vaultNumDockets ? (
      <Typography.Text><strong>Search for a docket</strong> to upload the documents into or create a new one:</Typography.Text>
    ) : (
      <Typography.Text>A docket is like a folder where you can organize your files in Files 4 Ever.<br /><strong>Click on “Create new Docket”</strong> to create your first docket and start uploading your files.</Typography.Text>
    )
  }
  _renderHeader() {
    return (
      <Row className="DocketController-header">
        <Col span={24}>
          <Typography.Title level={3}>Upload file(s)</Typography.Title>
        </Col>
        <Col span={24}>
          <Spin size="small" spinning={this.state.isLoading} />
          {!this.state.selectedDocket && !this.state.isLoading && this._renderSearchDocketText()}
        </Col>
      </Row>
    );
  }
  _renderDocketSearch() {
    return (
      <CommonDocketSearch
        app={this.props.app}
        selectedDocket={this.state.selectedDocket}
        onSelectDocket={this.handleSelectDocket}
        onCreateDocket={this.handleDocketCreate}
      />
    );
  }
  _renderFiles() {
    return (
      <Row className="DocketController-files">
        <Col span={24} style={{ marginBottom: 16 }}>
          <Typography.Title level={4} style={{ marginBottom: 0 }}>
            Files in this batch
          </Typography.Title>
          <Typography.Text disabled>
            {this.state.files.length} file(s) queued ({this._getUITotalSize(this.state.files)})
          </Typography.Text>
        </Col>
        <Col span={24}>
          <ul>
            {this.state.files.map((file, index) => {
              return (
                <li key={file.rowKey + index}>
                  <div>
                    <span className="file-name">{file.document_title} ({Utils.formatSizeUnits(file.size)})</span>
                    <Button
                      type="ghost"
                      shape="circle"
                      icon="delete"
                      onClick={() => this.handleFileDelete(file, index)}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </Col>
        <Col span={24}>
          <Button
            type="ghost"
            onClick={this.props.app.dropController.openDialog}
          >
            Add Document
          </Button>
        </Col>

        <CommonConfirmationModal
          onConfirmation={this.handleFileDeleteModalOk}
          confirmationText="Remove File"
          cancellationText="Close"
          onCancel={this.handleFileDeleteModalCancel}
          {...Utils.propagateRef(this, 'confirmationModal')}
        />
      </Row>
    );
  }
  //Helpers
  async _loadCurrentVaultNumDockets() {
    if (this.state.vaultNumDockets === undefined) {
      await this.props.app.sharedCache().reloadCurrentVault();
      this.setState({
        vaultNumDockets: this.props.app.sharedCache().selectedVault.vaultNumDockets,
        isLoading: false
      });
    } else if (this.state.isLoading) {
      this.setState({ isLoading: false });
    }
  }
  _getUITotalSize(files) {
    let total = 0;
    files.forEach((file) => {
      total += file.size;
    });
    return Utils.formatSizeUnits(total);
  }
  _removeFileByIndex(index) {
    const files = this.state.files;

    files.splice(index, 1);

    this.setState({
      files,
    });
  }
  _appendFiles(newFiles, docket) {
    newFiles = newFiles.filter((f) => !this.state.files.find((_f) => _f.path == f.path));
    let files = this.state.files.concat(newFiles);
    const safeDocket = docket || this.state.selectedDocket;
    if (safeDocket) {
      files = this._setFilesAttributes(this.state.files.concat(newFiles), safeDocket);
    }
    return { files, selectedDocket: safeDocket };
  }
  _setFilesAttributes(files, docket) {
    return files.map((file) => {
      file.attributes = {};
      if (docket?.attributes) {
        docket?.attributes?.forEach((elem) => {
          file.attributes[elem.id] = elem.value;
        });
      }
      file.tags = docket?.tags || [];
      return file;
    });
  }
}
