import React from 'react';
import autoBind from 'react-autobind';
import { Layout, Button } from 'antd';
//
// import { IDMGlobals } from '@ikonintegration/idmclient';
import CustomComponent from '../../ui-components/CustomComponent';
import CommonLoadingView from '../commonComponents/CommonLoadingView';
// resources
import '../../assets/stylesheets/CommonLogin.scss';
//
// import Globals from '../../config/Globals';
import config from '../../config/config';
import CommonFullOrangeScreenLayout from '../commonComponents/CommonFullOrangeScreenLayout';
//
const { Content } = Layout;
//
export default class CommonLoginView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = { isLoading: false };
    this.props.app.sessionWillLoadHandler = this.sessionWillLoadHandler;
    this.props.app.sessionDidLoadHandler = this.sessionDidLoadHandler;
    this.props.app.sessionDidFailLoadHandler = this.sessionDidFailLoadHandler;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Sign in - ${config.ApplicationName}`;
  }

  //session delegate -
  sessionWillLoadHandler() {
    if (this._isMounted) this.startLoading();
  }
  sessionDidLoadHandler() {
    if (this._isMounted) this.stopLoading(); //most of the cases it will be umounted already
  }
  sessionDidFailLoadHandler() {
    this.props.app.alertController.showErrorAlert(
      'Error!',
      'Could not load application information. Please, check your internet connection.'
    );
    if (this._isMounted) this.stopLoading();
  }

  // Actions
  async handleIPCSignin() {
    if (this.props.app.ipcManager.isDesktopHost()) {
      this.props.app.urlManager.openExternalPage(config.IntegrationsDesktopSubdomain + '.' + config.ApplicationDomain + '/login', true);
    } else if (this.props.app.ipcManager.isMobileHost()) {
      this.props.app.urlManager.openExternalPage(config.IntegrationsMobileSubdomain + '.' + config.ApplicationDomain + '/login', true);
    }
  }

  // UI
  render() {
    const isLoading = this.state.isLoading || this.props.app.isAuthenticating;
    const showIPCView = this.props.app.launchConfigManager.isAvailable() && this.props.app.ipcManager.anyIPCHost() && !isLoading;
    return (
      <Content className={'pageContentFullScreen' + showIPCView ? ' noPadding' : ''}>
        {showIPCView ? this._renderIPCContainer() : <CommonLoadingView isLoading={isLoading} />}
      </Content>
    );
  }

  /* Private UI */
  _renderIPCContainer() {
    return (
      <CommonFullOrangeScreenLayout centered app={this.props.app}
        title={this._getIPCMessage(true)} subtitle={this._getIPCMessage()}
        squares={{ large1: { style: { top: -20 } }, large2: { style: { bottom: -50 } } }}>
        <div className="container" style={{ marginTop: 35 }}>
          {(this.props.app.ipcManager.isDesktopHost() || this.props.app.ipcManager.isMobileHost()) &&
            <Button size='large' className='signinButton' type="secondary" onClick={this.handleIPCSignin.bind(this, 1)}>Sign In</Button>
          }
        </div>
      </CommonFullOrangeScreenLayout>
    );
  }
  _getIPCMessage(isTitle) {
    if (this.props.app.ipcManager.isLightningHost()) {
      if (isTitle) return 'Opss.. Your are not connected to Files4Ever!';
      else return 'Please, ask for your organization admin to review the configuration of your Files4Ever installation.';
    } else if (this.props.app.ipcManager.isDesktopHost()) {
      if (isTitle) return 'Please, signin to use Files4Ever Desktop';
      else return 'You will be redirect to the browser to complete the authentication there!';
    } else if (this.props.app.ipcManager.isMobileHost()) {
      if (isTitle) return 'Please, signin to use Files4Ever Mobile';
      else return 'You will be redirect to the browser to complete the authentication there!';
    } return null;
  }
}
