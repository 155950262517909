import React from 'react';
import autoBind from 'react-autobind';
//
import { Layout, Spin } from 'antd';
//
import CustomComponent from '../../../ui-components/CustomComponent';
import orangepiggy from '../../../assets/images/orangepiggy.svg';
//Style
import '../../../assets/stylesheets/SettingUpVaultView.scss';
//
/* eslint max-classes-per-file: ["error", 2] */
export default class SettingUpVaultView extends CustomComponent {
  constructor(props) {
    super(props);
    autoBind(this);
    this.interval = null;
  }

  componentDidMount() {
    this.interval = setInterval(this.handleReloadVaults, 2500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  async handleReloadVaults() {
    await this.props.app.checkForVaultSetupCompletion();
  }

  //UI
  render() {
    return (
      <Layout.Content className="settingUpVaultContainer">
        <img src={orangepiggy} alt="Files" width="120" />
        <h1>We are setting up your vault!</h1>
        <h2>Hey there, your vault is being set, this {`shouldn't`} take more than 1 minute.</h2>

        <div className="setting-up">
          <Spin size="large" />
          <span>Setting up your Vault...</span>
        </div>
      </Layout.Content>
    );
  }
}
